/**
 * @class mApp  Metronic App class
 */

var mApp = (function () {
  /**
   * Initializes metronic portlet
   */
  var initPortlet = function (el, options) {
    // init portlet tools
    el.mPortlet(options);
  };

  /**
   * Initializes metronic portlets
   */
  var initPortlets = function () {
    // init portlet tools
    $('[data-portlet="true"]').each(function () {
      var el = $(this);

      if (el.data('portlet-initialized') !== true) {
        initPortlet(el, {});
        el.data('portlet-initialized', true);
      }
    });
  };

  /**
   * Initializes scrollable contents
   */
  var initScrollables = function () {
    $('[data-scrollable="true"]').each(function () {
      var maxHeight;
      var height;
      var el = $(this);

      if (mUtil.isInResponsiveRange('tablet-and-mobile')) {
        if (el.data('mobile-max-height')) {
          maxHeight = el.data('mobile-max-height');
        } else {
          maxHeight = el.data('max-height');
        }

        if (el.data('mobile-height')) {
          height = el.data('mobile-height');
        } else {
          height = el.data('height');
        }
      } else {
        maxHeight = el.data('max-height');
        height = el.data('max-height');
      }

      if (maxHeight) {
        el.css('max-height', maxHeight);
      }
      if (height) {
        el.css('height', height);
      }

      mApp.initScroller(el, {});
    });
  };


  /**
   * Initializes Metronic custom tabs
   */
  var initCustomTabs = function () {
    // init bootstrap popover
    $('[data-tab-target]').each(function () {
      if ($(this).data('tabs-initialized')) {
        return;
      }

      $(this).on('click', function (e) {
        e.preventDefault();

        var tab = $(this);
        var tabs = tab.closest('[data-tabs="true"]');
        var contents = $(tabs.data('tabs-contents'));
        var content = $($(this).data('tab-target'));

        tabs.find('.m-tabs__item.m-tabs__item--active').removeClass('m-tabs__item--active');
        tab.addClass('m-tabs__item--active');

        contents
          .find('>.m-tabs-content__item.m-tabs-content__item--active')
          .removeClass('m-tabs-content__item--active');
        content.addClass('m-tabs-content__item--active');
      });

      $(this).data('tabs-initialized', true);
    });
  };
  /**
   * Initializes bootstrap collapse for Metronic's accordion feature
   */

  var hideTouchWarning = function () {
    jQuery.event.special.touchstart = {
      setup: function (_, ns, handle) {
        if (typeof this === 'function')
          if (ns.includes('noPreventDefault')) {
            this.addEventListener('touchstart', handle, { passive: false });
          } else {
            this.addEventListener('touchstart', handle, { passive: true });
          }
      },
    };
    jQuery.event.special.touchmove = {
      setup: function (_, ns, handle) {
        if (typeof this === 'function')
          if (ns.includes('noPreventDefault')) {
            this.addEventListener('touchmove', handle, { passive: false });
          } else {
            this.addEventListener('touchmove', handle, { passive: true });
          }
      },
    };
    jQuery.event.special.wheel = {
      setup: function (_, ns, handle) {
        if (typeof this === 'function')
          if (ns.includes('noPreventDefault')) {
            this.addEventListener('wheel', handle, { passive: false });
          } else {
            this.addEventListener('wheel', handle, { passive: true });
          }
      },
    };
  };

  return {
    /**
     * Main class initializer
     */
    init: function () {
      mApp.initComponents();
    },

    /**
     * Initializes components
     */
    initComponents: function () {
      hideTouchWarning();
      initScrollables();
      initPortlets();
      initCustomTabs();
    },

    /**
     * Init custom tabs
     */
    initCustomTabs: function () {
      initCustomTabs();
    },

    /**
     *
     * @param {object} el jQuery element object
     */
    // function to init portlet
    initPortlet: function (el, options) {
      initPortlet(el, options);
    },

    /**
     *
     * @param {object} el jQuery element object
     */
    // function to init portlets
    initPortlets: function () {
      initPortlets();
    },

    /**
     * Scrolls to an element with animation
     * @param {object} el jQuery element object
     * @param {number} offset Offset to element scroll position
     */
    scrollTo: function (target, offset) {
      const el = $(target);

      var pos = el && el.length > 0 ? el.offset().top : 0;
      pos = pos + (offset ? offset : 0);

      jQuery('html,body').animate(
        {
          scrollTop: pos,
        },
        'slow'
      );
    },

    /**
     * Scrolls until element is centered in the viewport
     * @param {object} el jQuery element object
     */
    // wrJangoer function to scroll(focus) to an element
    scrollToViewport: function (el) {
      var elOffset = $(el).offset().top;
      var elHeight = el.height();
      var windowHeight = mUtil.getViewPort().height;
      var offset = elOffset - (windowHeight / 2 - elHeight / 2);

      jQuery('html,body').animate(
        {
          scrollTop: offset,
        },
        'slow'
      );
    },

    /**
     * Scrolls to the top of the page
     */
    // function to scroll to the top
    scrollTop: function () {
      mApp.scrollTo();
    },

    /**
     * Initializes scrollable content using mCustomScrollbar plugin
     * @param {object} el jQuery element object
     * @param {object} options mCustomScrollbar plugin options(refer: http://manos.malihu.gr/jquery-custom-content-scroller/)
     */
    initScroller: function (el, options) {
      if (mUtil.isMobileDevice()) {
        el.css('overflow', 'auto');
      } else {
        el.mCustomScrollbar({
          scrollInertia: 0,
          autoDraggerLength: true,
          autoHideScrollbar: true,
          autoExpandScrollbar: false,
          alwaysShowScrollbar: 0,
          axis: el.data('axis') ? el.data('axis') : 'y',
          mouseWheel: {
            scrollAmount: 120,
            preventDefault: true,
          },
          setHeight: options.height ? options.height : '',
          theme: 'minimal-dark',
        });
      }
    },

    /**
     * Destroys scrollable content's mCustomScrollbar plugin instance
     * @param {object} el jQuery element object
     */
    destroyScroller: function (el) {
      el.mCustomScrollbar('destroy');
    },

    /**
     * Enable loader progress for button and other elements
     * @param {object} target jQuery element object
     * @param {object} options
     */
    progress: function (target, options) {
      var skin = options && options.skin ? options.skin : 'light';
      var alignment = options && options.alignment ? options.alignment : 'right';
      var size = options && options.size ? 'm-spinner--' + options.size : '';
      var classes = 'm-loader ' + 'm-loader--' + skin + ' m-loader--' + alignment + ' m-loader--' + size;

      mApp.unprogress(target);

      $(target).addClass(classes);
      $(target).data('progress-classes', classes);
    },

    /**
     * Disable loader progress for button and other elements
     * @param {object} target jQuery element object
     */
    unprogress: function (target) {
      $(target).removeClass($(target).data('progress-classes'));
    },
  };
})();

//== Initialize mApp class on document ready
$(function () {
  mApp.init();
});
