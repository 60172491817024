var mLayout = (function() {
  var horMenu;
  var asideMenu;
  var asideMenuOffcanvas;
  var horMenuOffcanvas;

  var initStickyHeader = function() {
    var header = $('.m-header');
    var options = {
      offset: {},
      minimize: {}
    };

    if (header.data('minimize-mobile') == 'hide') {
      options.minimize.mobile = {};
      options.minimize.mobile.on = 'm-header--hide';
      options.minimize.mobile.off = 'm-header--show';
    } else {
      options.minimize.mobile = false;
    }

    if (header.data('minimize') == 'minimize') {
      options.minimize.desktop = {};
      options.minimize.desktop.on = 'm-header--minimize-on';
      options.minimize.desktop.off = 'm-header--minimize-off';
    } else if (header.data('minimize') == 'hide') {
      options.minimize.desktop = {};
      options.minimize.desktop.on = 'm-header--hide';
      options.minimize.desktop.off = 'm-header--show';
    } else {
      options.minimize.desktop = false;
    }

    if (header.data('minimize-offset')) {
      options.offset.desktop = header.data('minimize-offset');
    }

    if (header.data('minimize-mobile-offset')) {
      options.offset.mobile = header.data('minimize-mobile-offset');
    }

    header.mHeader(options);
  };

  // handle horizontal menu
  var initHorMenu = function() {
    // init aside left offcanvas
    horMenuOffcanvas = $('#m_header_menu').mOffcanvas({
      class: 'm-aside-header-menu-mobile',
      overlay: true,
      close: '#m_aside_header_menu_mobile_close_btn',
      toggle: {
        target: '#m_aside_header_menu_mobile_toggle',
        state: 'm-brand__toggler--active'
      }
    });

    horMenu = $('#m_header_menu').mMenu({
      // submenu modes
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion'
      },
      // resize menu on window resize
      resize: {
        desktop: function() {
          var headerNavWidth = $('#m_header_nav').width();
          var headerMenuWidth = $('#m_header_menu_container').width();
          var headerTopbarWidth = $('#m_header_topbar').width();
          var spareWidth = 20;

          return (
            headerMenuWidth + headerTopbarWidth + spareWidth <= headerNavWidth
          );
        }
      }
    });
  };

  // handle vertical menu
  var initLeftAsideMenu = function() {
    var menu = $('#m_ver_menu');

    // init aside menu
    var menuOptions = {
      // submenu setup
      submenu: {
        desktop: {
          // by default the menu mode set to accordion in desktop mode
          default:
            menu.data('menu-dropdown') ? 'dropdown' : 'accordion',
          // whenever body has this class switch the menu mode to dropdown
          state: {
            body: 'm-aside-left--minimize',
            mode: 'dropdown'
          }
        },
        tablet: 'accordion', // menu set to accordion in tablet mode
        mobile: 'accordion' // menu set to accordion in mobile mode
      },

      //accordion setup
      accordion: {
        autoScroll: true,
        expandAll: false
      }
    };

    asideMenu = menu.mMenu(menuOptions);

    // handle fixed aside menu
    if (menu.data('menu-scrollable')) {
      function initScrollableMenu(obj) {
        if (mUtil.isInResponsiveRange('tablet-and-mobile')) {
          // destroy if the instance was previously created
          mApp.destroyScroller(obj);
          return;
        }

        var height =
          mUtil.getViewPort().height -
          $('.m-header').outerHeight() -
          ($('.m-aside-left .m-aside__header').length != 0
            ? $('.m-aside-left .m-aside__header').outerHeight()
            : 0) -
          ($('.m-aside-left .m-aside__footer').length != 0
            ? $('.m-aside-left .m-aside__footer').outerHeight()
            : 0);
        //- $('.m-footer').outerHeight();

        // create/re-create a new instance
        mApp.initScroller(obj, { height: height });
      }

      initScrollableMenu(asideMenu);

      mUtil.addResizeHandler(function() {
        initScrollableMenu(asideMenu);
      });
    }
  };

  // handle vertical menu
  var initLeftAside = function() {
    // init aside left offcanvas
    var asideOffcanvasClass = $('#m_aside_left').hasClass(
      'm-aside-left--offcanvas-default'
    )
      ? 'm-aside-left--offcanvas-default'
      : 'm-aside-left';

    asideMenuOffcanvas = $('#m_aside_left').mOffcanvas({
      class: asideOffcanvasClass,
      overlay: true,
      close: '#m_aside_left_close_btn',
      toggle: {
        target: '#m_aside_left_offcanvas_toggle',
        state: 'm-brand__toggler--active'
      }
    });
  };

  // handle sidebar toggle
  var initLeftAsideToggle = function() {
    // var asideLeftToggle =
    $('#m_aside_left_minimize_toggle')
      .mToggle({
        target: 'body',
        targetState: 'm-brand--minimize m-aside-left--minimize',
        togglerState: 'm-brand__toggler--active'
      })
      .on('toggle', function(toggle) {
        horMenu.pauseDropdownHover(800);
        asideMenu.pauseDropdownHover(800);

        //== Remember state in cookie
        Cookies.set('sidebar_toggle_state', toggle.getState());
      });

    //== Example: minimize the left aside on page load
    //== asideLeftToggle.toggleOn();

    $('#m_aside_left_hide_toggle')
      .mToggle({
        target: 'body',
        targetState: 'm-aside-left--hide',
        togglerState: 'm-brand__toggler--active'
      })
      .on('toggle', function() {
        horMenu.pauseDropdownHover(800);
        asideMenu.pauseDropdownHover(800);
      });
  };

  var initTopbar = function() {
    $('#m_aside_header_topbar_mobile_toggle').on('click', function() {
      $('body').toggleClass('m-topbar--on');
    });

    // Animated Notification Icon
    setInterval(function() {
      $('#m_topbar_notification_icon .m-nav__link-icon').addClass(
        'm-animate-shake'
      );
      $('#m_topbar_notification_icon .m-nav__link-badge').addClass(
        'm-animate-blink'
      );
    }, 3000);

    setInterval(function() {
      $('#m_topbar_notification_icon .m-nav__link-icon').removeClass(
        'm-animate-shake'
      );
      $('#m_topbar_notification_icon .m-nav__link-badge').removeClass(
        'm-animate-blink'
      );
    }, 6000);
  };

  var initScrollTop = function() {
    $('[data-toggle="m-scroll-top"]').mScrollTop({
      offset: 300,
      speed: 600
    });
  };

  return {
    init: function() {
      this.initHeader();
      this.initAside();
    },

    initHeader: function() {
      initStickyHeader();
      initHorMenu();
      initTopbar();
      initScrollTop();
    },

    initAside: function() {
      initLeftAside();
      initLeftAsideMenu();
      initLeftAsideToggle();
    },

    getAsideMenu: function() {
      return asideMenu;
    },

    closeMobileAsideMenuOffcanvas: function() {
      if (mUtil.isMobileDevice()) {
        asideMenuOffcanvas && asideMenuOffcanvas.hide();
      }
    },

    closeMobileHorMenuOffcanvas: function() {
      if (mUtil.isMobileDevice()) {
        horMenuOffcanvas && horMenuOffcanvas.hide();
      }
    }
  };
})();

$(function() {
  if (mUtil.isAngularVersion() === false) {
    mLayout.init();
  }
});
