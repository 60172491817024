(function($) {
  // plugin setup
  $.fn.mToggle = function(options) {
    // main object
    var toggle = this;
    var element = $(this);

    /********************
     ** PRIVATE METHODS
     ********************/
    var Plugin = {
      /**
       * Run
       */
      run: function(options) {
        if (!element.data('toggle')) {
          // create instance
          Plugin.init(options);
          Plugin.build();

          // assign instance to the element
          element.data('toggle', toggle);
        } else {
          // get instance from the element
          toggle = element.data('toggle');
        }

        return toggle;
      },

      /**
       * Handles subtoggle click toggle
       */
      init: function(options) {
        toggle.element = element;
        toggle.events = [];

        // merge default and user defined options
        toggle.options = $.extend(true, {}, $.fn.mToggle.defaults, options);

        toggle.target = $(toggle.options.target);
        toggle.targetState = toggle.options.targetState;
        toggle.togglerState = toggle.options.togglerState;

        toggle.state = mUtil.hasClasses(toggle.target, toggle.targetState)
          ? 'on'
          : 'off';
      },

      /**
       * Setup toggle
       */
      build: function() {
        element.on('click', Plugin.toggle);
      },

      /**
       * sync
       */
      sync: function() {
        $(element).data('toggle', toggle);
      },

      /**
       * Handles offcanvas click toggle
       */
      toggle: function() {
        if (toggle.state == 'off') {
          Plugin.toggleOn();
        } else {
          Plugin.toggleOff();
        }
        Plugin.eventTrigger('toggle');

        return toggle;
      },

      /**
       * Handles toggle click toggle
       */
      toggleOn: function() {
        Plugin.eventTrigger('beforeOn');

        toggle.target.addClass(toggle.targetState);

        if (toggle.togglerState) {
          element.addClass(toggle.togglerState);
        }

        toggle.state = 'on';

        Plugin.eventTrigger('afterOn');

        return toggle;
      },

      /**
       * Handles toggle click toggle
       */
      toggleOff: function() {
        Plugin.eventTrigger('beforeOff');

        toggle.target.removeClass(toggle.targetState);

        if (toggle.togglerState) {
          element.removeClass(toggle.togglerState);
        }

        toggle.state = 'off';

        Plugin.eventTrigger('afterOff');

        return toggle;
      },

      /**
       * Trigger events
       */
      eventTrigger: function(name) {
        toggle.trigger(name);
        for (let i = 0; i < toggle.events.length; i++) {
          var event = toggle.events[i];
          if (event.name == name) {
            if (event.one == true) {
              if (event.fired == false) {
                toggle.events[i].fired = true;
                return event.handler.call(this, toggle);
              }
            } else {
              return event.handler.call(this, toggle);
            }
          }
        }
      },

      addEvent: function(name, handler, one) {
        toggle.events.push({
          name: name,
          handler: handler,
          one: one,
          fired: false
        });

        Plugin.sync();

        return toggle;
      }
    };

    // init plugin
    Plugin.run.apply(this, [options]);

    /********************
     ** PUBLIC API METHODS
     ********************/

    /**
     * Get toggle state
     */
    toggle.getState = function() {
      return toggle.state;
    };

    /**
     * Toggle
     */
    toggle.toggle = function() {
      return Plugin.toggle();
    };

    /**
     * Toggle on
     */
    toggle.toggleOn = function() {
      return Plugin.toggleOn();
    };

    /**
     * Toggle off
     */
    toggle.toggleOff = function() {
      return Plugin.toggleOff();
    };

    /**
     * Attach event
     * @returns {mToggle}
     */
    toggle.on = function(name, handler) {
      return Plugin.addEvent(name, handler);
    };

    /**
     * Attach event that will be fired once
     * @returns {mToggle}
     */
    toggle.one = function(name, handler) {
      return Plugin.addEvent(name, handler, true);
    };

    return toggle;
  };

  // default options
  $.fn.mToggle.defaults = {
    togglerState: '',
    targetState: ''
  };
})(jQuery);
